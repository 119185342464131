import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({
  description,
  ogDescription,
  path,
  ogImage,
  meta,
  keywords,
  robots,
  ogType = "website",
  title = "",
  disableVwoAbTesting,
}) {
  const { site } = useStaticQuery(
    graphql`
      query DefaultSEOQuery {
        site {
          siteMetadata {
            title
            siteUrl
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ENGIE Casa`}
      htmlAttributes={{ lang: "it" }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: ogDescription || metaDescription,
        },
        {
          property: `og:type`,
          content: ogType,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: ogDescription || metaDescription,
        },
      ]
        .concat(
          path
            ? [
                {
                  name: `og:url`,
                  content: `${site.siteMetadata.siteUrl}${path}`,
                },
                {
                  name: `twitter:url`,
                  content: `${site.siteMetadata.siteUrl}${path}`,
                },
              ]
            : []
        )
        .concat(
          ogImage?.resize
            ? [
                {
                  property: `og:image`,
                  content: ogImage.resize.src,
                },
                {
                  property: `twitter:image`,
                  content: ogImage.resize.src,
                },
              ]
            : []
        )
        .concat(
          ogImage?.resize
            ? [
                {
                  property: "og:image:width",
                  content: ogImage.resize.width,
                },
                {
                  property: "og:image:height",
                  content: ogImage.resize.height,
                },
                {
                  property: "twitter:image:width",
                  content: ogImage.resize.width,
                },
                {
                  property: "twitter:image:height",
                  content: ogImage.resize.height,
                },
              ]
            : []
        )
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(
          robots ? { name: "robots", value: robots } : { name: "robots", value: "index,follow" }
        )
        .concat(meta)}
      link={
        path
          ? [
              {
                rel: "canonical",
                href: `${site.siteMetadata.siteUrl}${path}`,
              },
            ]
          : []
      }
    >
      {!disableVwoAbTesting && (
        <script type="text/plain" className="optanon-category-C0002">
          {`window._vwo_code = window._vwo_code || (function(){
      var account_id=358304,
      settings_tolerance=2000,
      library_tolerance=2500,
      use_existing_jquery=false,
      is_spa=1,
      hide_element='body',
      /* DO NOT EDIT BELOW THIS LINE */
      f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){

      window.settings_timer=setTimeout('_vwo_code.finish()',settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());
      `}
        </script>
      )}
    </Helmet>
  );
}

SEO.defaultProps = {
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default SEO;
