import React from "react";
import PreviewItem from "../components/preview/preview-item";
// Preview HOC https://reactjs.org/docs/higher-order-components.html

// This function takes a component...
const withPreview = (WrappedComponent, options = {}) => {
  // ...and returns another component...
  if (!WrappedComponent) return null;
  const { subField, fixed } = options;
  return ({ data, ...other }) => {
    const fieldsData = (subField ? data[subField] : data)?.liferayFields;
    return process.env.EDIT_MODE === "true" && fieldsData?.siteId && fieldsData?.articleId ? (
      <PreviewItem fascia={fieldsData} fixed={fixed}>
        <WrappedComponent data={data} {...other} />
      </PreviewItem>
    ) : (
      <WrappedComponent data={data} {...other} />
    );
  };
};

export default withPreview;
